<script setup lang="ts">
const router = useRouter()

const teams = [{
	label: 'Nibb Finance',
	avatar: {
		src: '/logo_nibb_finance.svg'
	}
}]
const actionsEmpresa = [
	{
		disabled: false,
		label: 'Criar empresa',
		icon: 'i-heroicons-plus-circle',
		click: () => {
			router.push('/nova-empresa')
		}
	},
	{
		disabled: false,
		label: 'Gerenciar empresa',
		icon: 'i-heroicons-cog-8-tooth',
		click: () => {
			router.push('/empresa')
		}
	}
]

const actions = [{
	label: 'Criar usuário',
	disabled: false,
	icon: 'i-heroicons:user-plus'
}, {
	label: 'Criar time',
	disabled: true,
	icon: 'i-heroicons-plus-circle'
}, {
	disabled: true,
	label: 'Gerenciar times',
	icon: 'i-heroicons-cog-8-tooth'
}]

const team = ref(teams[0])
</script>

<template>
	<UDropdown
		v-slot="{ open }"
		mode="hover"
		:items="[teams, actionsEmpresa, actions]"
		class="w-full"
		:ui="{ width: 'w-full' }"
		:popper="{ strategy: 'absolute' }"
	>
		<UButton
			color="gray"
			variant="ghost"
			:class="[open && 'bg-gray-50 dark:bg-gray-800']"
			class="w-full"
		>
			<UAvatar
				:src="team.avatar.src"
				size="2xs"
			/>

			<span class="truncate text-gray-900 dark:text-white font-semibold">{{ team.label }}</span>
		</UButton>
	</UDropdown>
</template>
